import React from "react";
import "./App.scss";

import Link from "./components/Link"

import Typewriter from "typewriter-effect";

const config = require("./config.json")

function App() {
  const [clicked, setClicked] = React.useState(0)
  const [heart, setHeart] = React.useState(0)

  var links = []
  config.links.forEach(item => {
    links.push(
      <Link
        name={item.name}
        img={item.img}
        href={item.href}
        rel={item.rel}
        invert={item.invert}
      />
    )
  })

  var clickedLinks = []
  config.clickedLinks.forEach(item => {
    clickedLinks.push(
      <Link
        name={item.name}
        img={item.img}
        href={item.href}
        rel={item.rel}
        invert={item.invert}
        clicked={clicked}
      />
    )
  })

  var bkgrndSpans = []
  for(var i = 0; i < 36; i++) {
    bkgrndSpans.push(
      <span />
    )
  }

  return (
    <div className="App">
      <div className="App-background">
        {bkgrndSpans}
      </div>
      <header className="App-header">
        <div className="App-item">
          <img
            src={config.pfp}
            className="header-image"
            alt="near"
            onDoubleClick={() => clicked === 0 ? setClicked(1) : setClicked(0)}
            clicked={clicked}
          />
          <p className="header-title">
            <h1>near</h1>
            <h2>⟦ any pronouns / no pref ⟧</h2>
            <h2>⟦ likes men ⟧</h2>
          </p>

          <div className="header-typewriter">
            <Typewriter
              options={{
                strings: config.taglines,
                autoStart: true,
                loop: true,
              }}
            />
          </div>
        </div>
      </header>

      <hr />

      <body className="App-body">
        <div className="links">
          {links}
        </div>

        {
          clicked === 1 ? (
            <div className="links clicked-links">
              {clickedLinks}
            </div>
          ) : (
            <div />
          )
        }
      </body>

      <hr />

      <footer className="App-footer">
        <span className="App-item">just foxxin' around and messin' with compooters and moosics! ;3</span>

        <img
          className="footer-img"
          src="https://raw.githubusercontent.com/twitter/twemoji/master/assets/svg/2764.svg"
          alt="❤"
          width="150px"
          onClick={() => setHeart(1)}
          onAnimationEnd={() => setHeart(0)}
          heart={heart}
        />

        <a className="App-link" href="https://git.nearlyafox.pet/nearlyafox/nearlyafox.pet">source code</a>
      </footer>

      <hr />
    </div>
  );
}

export default App;
